import { McBreadcrumbsService } from '../service/mc-breadcrumbs.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { IBreadcrumb } from '../mc-breadcrumbs.shared';
import { Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mc-breadcrumbs',
  template: `<ol *ngIf="crumbs.length" class="breadcrumb">
  <li *ngFor="let crumb of crumbs; let last = last" [ngClass]="{ 'active': last }" class="breadcrumb-item">
    <a *ngIf="!last" [routerLink]="crumb.path">{{ crumb.text }}</a>
    <span *ngIf="last">{{ crumb.text }}</span>
  </li>
</ol>
`
})
export class McBreadcrumbsComponent implements OnInit, OnDestroy {
  constructor(public service: McBreadcrumbsService) {}

  crumbs: IBreadcrumb[];

  subscriptions = new Array<Subscription>();

  public ngOnInit(): void {
    const s = this.service.crumbs$.subscribe(x => {
      this.crumbs = x;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
